import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import headStand from "./headStand.jpg";
import handstand from "./handstand.jpg"
import axios from 'axios'

const styles = {
  card: {
      maxWidth: 8000,
      marginBottom: 10
  },
  media: {
      // ⚠️ object-fit is not supported by IE 11.
      objectFit: "cover"
  }
};

function IrlCourse(props) {

    const saveClick = async () => {
        const timeNow = new Date().getTime();
        const userAgent = navigator.userAgent;
        const languages = navigator.languages

        await axios.post('https://us-central1-learnbreaking.cloudfunctions.net/save', {
            timeNow,
            userAgent,
            languages
          });
    }
  

    useEffect(async () => {
      saveClick()
    }, []);

  const { classes } = props;
  const courses = [
      // {
      //   url: 'https://forms.gle/4yvVifk656F9H85D9', name: 'Bodyweight training course',
      //   description: 'If you ever wanted to learn basic calisthenics, ex. handstand, headstand and cartwheel, now is your chance! Our teachers have over 10 years experience in bodyweight training and coaching. This course is beginner friendly, but if you are advanced, we can also provide guidance to hard exercises.',
      //   when: 'Thursdays: 18.00-19.00 (1.7-29.7)',
      //   where: 'Töölönlahden puisto, 00100 Helsinki',
      //   image: headStand,
      //   price: 49
      // },
      {
        url: 'https://forms.gle/ZxHLzTZiZzZeuXyQA',
        name: 'Kehonpainoharjoittelukurssi',
        description: 'Jos koskaan olet halunnut opetella kärrynpyörän, päällä -tai käsilläseisonnan, nyt sinulla on erinomainen mahdollisuus siihen. Tällä kurssilla opit perusteet tehokkaaseen kehonpainoharjoitteluun pätevien opettajien johdatuksella.',
        when: 'Ryhmä A: 17.00-18.00 ja B: 18.15-19.15 (11.12.2022)',
        where: 'Kuopiohalli',
        image: handstand,
        price: 10
      }
  ]
  const windowIsSmall = window.innerWidth < 750

  return (
        <div style={windowIsSmall ? {}: { display: 'flex', justifyContent: "center"}} >
          <div  style={windowIsSmall ? {}: { display: 'flex',width: "50%"}}>
                {courses.map(({ image, url, name, when, where, price, description }) =>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Course"
                                className={classes.media}
                                height="180"
                                image={image}
                                title="Course"
                                onClick={() => window.open(url)}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {name}
                                </Typography>
                          
                                <Typography variant="h9" component="p">
                                <b>{"Mitä:"+ "\n"}</b>{description}
                                </Typography>
                                <br></br>
                                <Typography variant="h9" component="p">
                                <b>{"Milloin:"+ "\n"}</b>{when}
                                </Typography>
                                <br></br>
                                <Typography variant="h9" component="p">
                                <b>{"Missä:"+ "\n"}</b> {where}
                                </Typography>
                                <br></br>
                                <Typography variant="h9" component="p">
                                <b>{"Hinta:"+ "\n"}</b> {price} €  {" (opiskelijoille ilmainen)"}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button
                                size="medium"
                                color="primary"
                                onClick={() => window.open(url)}
                            >
                                Ilmoittaudu!
                          </Button>
                        </CardActions>
                    </Card>
                )}
                </div>
        </div>
  );
}

IrlCourse.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IrlCourse);




