import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebase from "firebase";
import Dashboard from './Components/Dashboard';
import PrimarySearchAppBar from "./Components/PrimarySearchAppBar";
import IrlCourses from "./Components/IrlCourses";
import CookieConsent, { Cookies } from "react-cookie-consent";


class App extends Component {
  componentDidMount() {
    firebase.initializeApp({
      apiKey: "AIzaSyB5t62cKxYZRkklTd_lxBWoELUYknPnU5c",
      authDomain: "learnbreaking.firebaseapp.com",
      databaseURL: "https://learnbreaking.firebaseio.com",
      projectId: "learnbreaking",
      storageBucket: "learnbreaking.appspot.com",
      messagingSenderId: "284818070076",
      appId: "1:284818070076:web:a8b30e7c75793742858139",
      measurementId: "G-V5F9XHVHVX"
    });

  }

  setTrackerOn() {
      const existingScript = document.getElementById('lead');
      console.log('existingScript', existingScript)
      if (!existingScript) {
        // TODO
        // TURN LEADFINDER SCRIPT OFF OR ON HERE
        //const script = document.createElement('script');
        // script.src = 'https://maps.googleapis.com/maps/api/js';
        // script.id = 'googleMaps';
        //const scriptData = `<script id="lead"> (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)},1); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('3P1w24db92J8mY5n'); </script>`
        //document.body.appendChild(scriptData);
        // script.onload = () => { 
        //   if (callback) callback();
        // };
      }
  }
  render() {
    return (
      <BrowserRouter>
        <PrimarySearchAppBar />
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="cookiesAllowed"
          expires={150}
          onAccept={() => {
            this.setTrackerOn()
          }}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>            
        <Switch>
          <Route exact path="/" component={IrlCourses}  /> 
          <Route exact path="/courses" component={IrlCourses} />
          <Route
            render={() => {
              return <p> Page not found </p>;
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
