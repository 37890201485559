import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import headStandAd from "./headStandAd.png";
import headSpin from "./headSpin.png";
import split from "./split.png";

class Featured extends Component {
  render() {
    return (
      <Carousel
        infiniteLoop
      >
        <div>
          <img src={headStandAd} />
          <p className="legend"><a href="/courses">Enroll to course</a></p>
        </div>
        <div>
          <img src={headSpin} />
          <p className="legend"><a href="https://youtu.be/84p3R5-xtXM">Watch</a></p>
        </div>
        <div>
          <img src={split} />
          <p className="legend"><a href="https://youtu.be/oZNd9DVvMss">Watch</a></p>
        </div>
      </Carousel>
    );
  }
};

export default Featured;